<template>
  <div>
    <Head :title="$t('layout.Notice')"></Head>
    <!-- 背景 -->
    <img src="@/image/bg_03.jpg" class="bg" alt="" :style="'width:'+$setWidth">
    <div class="notice" :style="'width:'+$setWidth">
      <div class="notice_box" v-for="(item,index) in notice" :key="index">
        <div class="notice_title common_text">{{item.title}}</div>
        <div class="notice_text common_text" style="font-size:calc(var(--text) - 2px)" v-html="zifu(item.content)"></div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  props:['list'],
  data () {
    return {
      number: 0,
      notice:[],
      last_page:0,
      current_page:0,
    }
  },
  computed: {
    
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      this.get('/api/Community/announcement', {page:this.current_page}, 'POST').then(res => {
        this.last_page = res.list.last_page;//分页总数
        this.current_page = res.list.current_page;//当前页
        if (this.current_page==1) {
          this.notice = res.list.data
        } else {
          if(res.list.data.length>0){
            this.notice = this.notice.concat(res.list.data)
          }
        }
      })
    },
    zifu(value){
      if(value!=null){
        value = value.replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'")
            .replace(/&nbsp;/g, ' ')
        return value
      }else{
        return value
      }
    }
  }
}
</script>
 
<style lang="scss" scoped>
  .bg{
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .notice{
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    .notice_box{
      background-color: rgba(#1A328B,0.5);
      color: #ffffff;
      border-radius: 10px;
      padding: 0 10px;
      margin-top: 10px;
      .notice_title{
        padding: 10px 0;
        margin-bottom: 5px;
        border-bottom: 1px solid #1A328B;
      }
      .notice_text{
        padding: 3px 0;
      }
    }
  }
</style>